<template>
  <b-row>
    <div class="landing-cover">
      <div class="d-flex flex-column align-items-center">
        <h3 class="text-title fw-semi-bold text-white text-center mb-5 mb-sm-4">
          We Are Influencer Marketing.
        </h3>
        <div>
          <b-form>
            <b-form-input
              class="container-search"
              placeholder="Find creators..."
              v-model="search"
            >
            </b-form-input>
            <b-icon
              class="icon-search"
              icon="search"
              @click="searchCategory()"
            ></b-icon>
          </b-form>
        </div>
        <div>
          <b-button pill class="button-create" variant="button-create">
            Create a Marketer Pro Account
          </b-button>
        </div>
      </div>
    </div>
    <div class="col-12">
        <b-row class="container-proposals">
        <div>
          <span class="text-proposals">Propuestas por red social</span>
        </div>
        <div>
          <b-link disabled class="link-view-all">Ver todos</b-link>
        </div>
      </b-row>
      
      <b-row class="container-cards">
        <card-category
          v-for="(item, index) in results.slice(0, 4)"
          :key="index"
          :img_profile="item.images.media"
          :network="utils.getImageNetwork(item.user_network.network)"
          :user_name="item.user_network.username"
          :followers="utils.getFormat(item.user_network.followers)"
          :description="item.description"
          :net="utils.capitalize(item.user_network.network)"
          :color="utils.getColorFollowers(item.user_network.network)"
        >
        </card-category>
      </b-row>
    </div>

    <div class="col-12">
      <b-row class="container-proposals">
        <div>
          <span class="text-proposals">Propuestas por red social</span>
        </div>
        <div>
          <b-link disabled class="link-view-all">Ver todos</b-link>
        </div>
      </b-row>
      <b-row class="container-cards">
          <card-network  v-for="(item, index) in network"
          :key="index"
          :net="utils.capitalize(item)"
          :icon="utils.getIconWhite(item)"
          ></card-network>
      </b-row>
    </div>
  </b-row>
</template>
  
<script>
import {
  BLink,
  BRow,
  BForm,
  BFormInput,
  BIcon,
  BButton,
} from "bootstrap-vue";
import cardCategory from "@/views/pages/creator/cardCategory.vue";
import cardNetwork from "@/views/pages/creator/cardNetwork.vue";
import utils from "@/libs/utils";
import service from "@/services/creator";

export default {
  components: {
    BLink,
    cardNetwork,
    cardCategory,
    BIcon,
    BRow,
    BForm,
    BFormInput,
    BButton,
  },
  data() {
    return {
      utils,
      search: "",
      results: {},
      network: ['tiktok', 'instagram','youtube','facebook','linkedin','twitch','twitter','spotify','pinterest','blog','snapchat']
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    searchCategory() {
      const query = {
        network: "instagram",
        query: this.search,
      };
      service.searchProposals(query).then((response) => {
        return response;
      });
    },
    getProposal() {
      service.getProposals().then((response) => {
        this.results = response.results;
      });
    },
    getAll() {
      service.searchProposals({}).then((response) => {
        this.results = response.results;
      });
    },
  },
};
</script>
<style>
.link-view-all {
  font-weight: 400;
  color: #55a6c4 !important;
}
.text-proposals {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}
.container-proposals {
  display: flex;
  justify-content: space-between !important;
  margin: 67px 10px 10px 8px;
}
.container-cards {
  display: flex;
  justify-content: space-between !important;
}
.button-create {
  margin: 22px;
  background: rgba(248, 249, 250, 0.2) !important;
  color: #f8f9fa;
}
.button-create:hover {
  margin: 22px;
  background: rgba(248, 249, 250, 0.792) !important;
}
.icon-search {
  position: absolute;
  color: #6c757d;
  z-index: 8;
  margin-top: -41px;
  margin-left: 3px;
  font-size: 40px;
  cursor: pointer;
  padding: 8px;
}
.container-search { 
  border-radius: 1.357rem;
  padding-left: 40px;
  width: 550px;
  height: 45px;
}
.text-title {
  font-size: calc(1.409375rem + 1.9125vw);
  font-weight: bold;
  line-height: 1.2;
}
.landing-cover {
  padding-top: 8.5rem;
  padding: 6.5rem 0 3.5rem 0;
  width: 100%;
  background: radial-gradient(75% 175% at 50% -55%, #fcd751 0%, #e94287 100%);
}
@media (max-width: 615px) {
 .container-search { 
   display: flex;
   width: 100%;
 }
 @media (max-width: 459px) {
 .container-cards { 
   justify-content: center !important;
  }
 }
}
</style>