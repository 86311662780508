<template>
  <b-row>
    <div>
      <b-card class="container-card-network" body-class="p-0">
        <b-card-text class="network-text">
          <span :class="`${icon} icon-network`"></span>
          {{ net }}
        </b-card-text>
        <b-img
          class="container-img-network"
          src="https://picsum.photos/900/250/?image=3"
        ></b-img>
          
      </b-card>
    </div>
  </b-row>
</template>
        
<script>
import { BRow, BCard, BCardText, BImg } from "bootstrap-vue";

export default {
  props: {
    net: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  },
  components: {
    BImg,
    BCardText,
    BCard,
    BRow,
  },
};
</script>
        
<style>
.icon-network {
    font-size: 19px;
}
.network-text {
    position: absolute;
    font-weight: bold;
    color: white;
    height: 110px;
    width: 195px;
    border-radius: 5%;
    padding: 81px 20px 20px 20px;
}
.network-text:hover {
    background: rgba(0, 0, 0, 0.17);

}
.container-card-network {
  height: 110px;
  width: 195px;
  cursor: pointer;
  margin: 30px;
}
.container-img-network {
  width: 100%;
  height: 100%;
  border-radius: 4%;
  object-fit: cover;
}
.container-img-network:hover {
  width: 100%;
  height: 100%;
  border-radius: 4%;
}
@media (max-width: 459px) {
 .container-card-network { 
  width: 250px;
 }
 .network-text { 
  width: 250px;
 }
}
</style>