<template>
  <b-row>
    <b-col>
      <div>
          <b-card
            header-class="container-header"
            body-class="p-0"
            class="container-card-category m-2"
            footer-class="p-0"
          >
            <template #header>
              <b-avatar size="2.8rem" :src="img_profile"></b-avatar>
              <div class="col-9 container-influencer">
                <span class="container-username">{{ user_name }}</span>
                <b-row>
                <span class="container-followers" :class="color"
                  ><b-avatar
                    size="1rem"
                    class="avatar-network"
                    :src="network"
                    variant="light"
                  ></b-avatar
                  ><strong> {{ followers }}</strong> Followers</span
                >
                </b-row>
              </div>
            </template>
            <b-img
              class="container-img"
              src="https://placekitten.com/g/300/450"
              fluid
              alt="Responsive image"
            >
            </b-img>
            <template #footer>
              <div class="container-price">
                <span class="text-network">{{ net }}</span>
                <span class="text-price">$450</span>
              </div>
              <div class="container-footer">
                <span class="text-footer">
                  {{ description }}
                </span>
              </div>
            </template>
          </b-card>
      </div>
    </b-col>
  </b-row>
</template>
      
<script>
import { BCol, BRow, BCard, BImg, BAvatar } from "bootstrap-vue";

export default {
  props: {
    color: {
      type: String,
      default: ""
    },
    net: {
      type: String,
      default: ""
    },
    img_profile: {
      type: String,
      default: ""
    },
    network: {
      type: String,
      default: ""
    },
    user_name: {
      type: String,
      default: ""
    },
    followers: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: ""
    },
  },
  components: {
    BAvatar,
    BCard,
    BCol,
    BRow,
    BImg,
  },
};
</script>
      
<style>
.text-footer {
  color: #212529 !important;
  font-size: .99em;
}
.container-footer {
  padding: 7px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
}
.text-price {
  font-weight: bold !important;
}
.text-network {
  color: #55a6c4;
  font-size: .90rem;
}
.container-price {
  --bs-bg-opacity: 1;
  background: #f8f9fa;
  padding: 7px;
  display: flex;
  justify-content: space-between !important;
}
.avatar-network {
  margin: 0px 0px 5px;
}
.container-influencer {
  padding: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.container-header {
  padding: 0.5rem !important;
  justify-content: start !important;
}
.container-username {
  font-weight: bold;
  font-size: 1.1rem !important;
}
.container-followers {
  padding-left: 28px;
  white-space: nowrap;
  font-size: .95rem;
}
.container-card-category {
  height: 458px;
  width: 290px;
  cursor: pointer;
  border: .0625rem solid rgba(222,226,230,.7) !important;

}
.container-card-category:hover {
  box-shadow: 5px 5px 6px  #c7d0d8;

}
.container-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 670px) {
 .container-card-category { 
   display: flex;
   width: 90%;
 }
}
</style>